import { useQuery } from '@tanstack/react-query';
import { TRADING_ELOQUENT as ELOQUENT } from 'config/constants';
import { queryClientKeys } from 'config/executors/apiInterceptor';

/**
 * Get products
 * @param {Object} data
 * @returns {Promise}
 */
export function getBrokerages(data, { token }) {
    return ELOQUENT.Brokerages.index({
        ...data,
        per_page: 100
    }, token);
}

export const useGetBrokeragesApi = (apiCaller, data, enabled) => {
    const query = useQuery({
        queryKey: [queryClientKeys.useGetBrokeragesApi, data],
        queryFn: async ee => await apiCaller(getBrokerages, data),
        enabled
    });

    return { ...query, data: query.data?.data };
};

/**
 * Get Brokerages
 * @param {Object} data
 * @returns {Promise}
 */
export function getAllBrokerages(data, { token }) {
    return ELOQUENT.Brokerages.all(data, token);
}

export const useGetAllBrokeragesApi = (apiCaller, data) => {
    const query = useQuery({
        queryKey: [queryClientKeys.useGetAllBrokeragesApi, data],
        queryFn: async ee => await apiCaller(getAllBrokerages, data)
    });

    return { ...query, data: query.data?.data };
};

/**
 * add many Brokerages
 * @param {Array} ids
 * @returns {Promise}
 */

export function addManyBrokerages(ids, { token }) {
    return ELOQUENT.Brokerages.storeMany(ids, token);
}

/**
 * activate a Brokerage
 * @param {Number} id
 * @returns {Promise}
 */
export function activateBrokerage(id, { token }) {
    return ELOQUENT.Brokerages.activate(id, token);
}

/**
 * deactivate a Brokerage
 * @param {Number} id
 * @returns {Promise}
 */
export function deactivateBrokerage(id, { token }) {
    return ELOQUENT.Brokerages.deactivate(id, token);
}

/**
 * update a Brokerage
 * @param {Number} id
 * @returns {object}  data
 */

export function updateBrokerage(id, data, { token }) {
    return ELOQUENT.Brokerages.update(id, data, token);
}

/**
 * approve a Brokerage
 * @param {Number} id
 * @returns {Promise}
 */
export function approveBrokerage(id, { token }) {
    return ELOQUENT.Brokerages.approve(id, token);
}

/**
 * create an external brokerage
 * @param {Object} data
 * @returns {Promise}
 */
export function createExternalBrokerage(data, { token }) {
    return ELOQUENT.Brokerages.storeExternal(data, token);
}

/**
 * update an external brokerage
 * @param {Number} brokerageId
 * @param {Object} data
 * @returns {Promise}
 */
export function updateExternalBrokerage(brokerageId, data, { token }) {
    return ELOQUENT.Brokerages.updateExternal(brokerageId, data, token);
}

/**
 * get brokerage web public data by subdomain
 * @param {String} brokerageSubdomain
 * @returns {Promise}
 */
export function getBrokeragePublic(brokerageSubdomain, { token }) {
    if (!brokerageSubdomain) return null;

    return ELOQUENT.Brokerages.getBrokerageByName(brokerageSubdomain, token);
}

export const useGetBrokeragePublicApi = (apiCaller, subdomain) => {
    return useQuery({
        queryKey: [queryClientKeys.useGetBrokeragePublicApi(subdomain), {}],
        queryFn: async () => await apiCaller(getBrokeragePublic, subdomain),
        staleTime: 1000 * 60 * 60
    });
};
