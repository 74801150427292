import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { TRADING_ELOQUENT as ELOQUENT } from 'config/constants';
import { queryClientKeys } from 'config/executors/apiInterceptor';

/**
 * Get logged in user's profile data
 * @param {Object} data
 * @returns {Promise}
 */
export function getProductTypes(data, { token }) {
    return ELOQUENT.ProductTypes.index(data, token);
}

export function useGetProductTypeInfinite(apiCaller, initialPageParam) {
    return useInfiniteQuery({
        queryKey: [queryClientKeys.useGetProductTypeInfinite, initialPageParam],
        initialPageParam: initialPageParam,
        queryFn: (ee) => {
            const dataF = ee.queryKey[1];

            return apiCaller(getProductTypes, { ...dataF, ...ee.pageParam });
        },
        getNextPageParam: (lastPage, _, lastPageParam) => {
            if (!lastPage?.data?.next_page_url) return undefined;

            return {
                ...lastPageParam,
                page: lastPage?.data?.next_page_url
                    ? lastPage?.data?.current_page + 1
                    : lastPage?.data?.current_page
            };
        }
    });
}

/**
 * Get all tenors
 * @param {Object} data
 * @returns {Promise}
 */
export function requesRoamingProductAccess(data, { token }) {
    return ELOQUENT.ProductTypes.tradeRequest(data, token);
}

export const useRequesRoamingProductAccessApi = (apiCaller) => {
    return useMutation({
        mutationFn: async productId => await apiCaller(requesRoamingProductAccess, productId)
    });
};

/**
 * Get all tenors
 * @param {Object} data
 * @returns {Promise}
 */
export function getAllTenors(data, { token }) {
    return ELOQUENT.ProductTypes.tenors(data, token);
}

/**
 * Get products without pagination
 * @param {Object} data
 * @returns {Promise}
 */
export function getAllProductTypes(data, { token }) {
    return ELOQUENT.ProductTypes.all(data, token);
}

/**
 * Get products with pagination
 * @param {Object} data
 * @returns {Promise}
 */
export function getPaginatedProductTypes(data, { token }) {
    return ELOQUENT.ProductTypes.index(data, token);
}

/**
 * Create product type
 * @param {Object} data
 * @returns {Promise}
 */
export function createProductTypes(data, { token }) {
    return ELOQUENT.ProductTypes.create(data, token);
}

/**
 * Update product type
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateProductTypes(id, data, { token }) {
    return ELOQUENT.ProductTypes.update(id, data, token);
}

/**
 * Update status of product type
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateStatusProductTypes(id, data, { token }) {
    return ELOQUENT.ProductTypes.updateStatus(id, data, token);
}
