import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import NumberInput from 'containers/App/components/Input/NumberInput';
import Modal from 'containers/App/components/Modal/Modal';
import Button from 'creatella-react-components/lib/Button';
import Switch from 'containers/App/components/Switch/Switch';
import AppLoadingOverlay from 'containers/App/components/Loading/AppLoadingOverlay';
import './ModalCreateOrderScreen.scss';
import { USER_ROLES } from 'config/constants';
import BrokerageSelect from 'containers/App/components/BrokerSelect/BrokerageSelect';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import classNames from 'classnames';
import infoGrey from 'assets/images/general/info-grey.svg';
import GTCCheckBox from 'containers/App/components/gTcCheckBox';
import { isInMainPlatform } from 'utils/helpers';

ModalCreateOrderScreen.propTypes = {
    isVisible: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isProductLoading: PropTypes.bool,
    isFormValid: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    handleClose: PropTypes.func.isRequired,
    handleCreateFieldChange: PropTypes.func.isRequired,
    handleSellClick: PropTypes.func.isRequired,
    handleBuyClick: PropTypes.func.isRequired,
    orderToCreate: PropTypes.object.isRequired,
    productTypes: PropTypes.array.isRequired,
    products: PropTypes.array.isRequired,
    brokeragesList: PropTypes.array,
    roleType: PropTypes.number.isRequired,
    companies: PropTypes.array,
    secondaryCompanies: PropTypes.array,
    selectedCompany: PropTypes.object,
    selectedSecondaryCompany: PropTypes.object,
    isBlock: PropTypes.bool.isRequired,
    counterparties: PropTypes.array,
    selectedCounterparty: PropTypes.object,
    isCounterpartyLoading: PropTypes.bool.isRequired,
    profile: PropTypes.object.isRequired,
    isGtc: PropTypes.bool,
    setIsGtc: PropTypes.func,
    platformTitle: PropTypes.string

};

function ModalCreateOrderScreen({
    platformTitle,
    isVisible,
    isProcessing,
    isProductLoading,
    isFormValid,
    handleClose,
    handleSellClick,
    handleBuyClick,
    handleCreateFieldChange,
    orderToCreate,
    productTypes,
    products,
    brokeragesList,
    roleType,
    companies,
    secondaryCompanies,
    selectedCompany,
    selectedSecondaryCompany,
    isBlock,
    counterparties,
    selectedCounterparty,
    isCounterpartyLoading,
    profile,
    isGtc,
    setIsGtc
}) {
    const [conditionsActive, setConditionsActive] = useState(
        !!orderToCreate.note.value
    );

    const handleFieldChange = (field) => async (e) => {
        let value;

        if (field === 'is_firm') {
            value = !e;
        } else if (field === 'is_visible') {
            value = e;
        } else {
            value = e.target.value;
        }

        await handleCreateFieldChange(field, value);
    };

    const onClickConditions = async () => {
        if (conditionsActive) {
            setConditionsActive(false);
            handleFieldChange('note', '');
            await handleCreateFieldChange('note', '');
        } else {
            setConditionsActive(true);
        }
    };

    const handleBrokerageChange = useCallback(

        async (value) => {
            await handleCreateFieldChange('brokerages', value);
        },
        [handleCreateFieldChange]
    );

    return (
        <Modal
            className='modal-create-order-screen'
            onClose={handleClose}
            isVisible={isVisible}
            title={isBlock ? 'New Block Order' : 'New Order'}>
            {isProcessing && <AppLoadingOverlay />}

            <form className='create-order__form'>
                <select
                    value={orderToCreate.product_type_id.value}
                    onChange={handleFieldChange('product_type_id')}
                    className={`create-order__field-select ${!orderToCreate.product_type_id.value
                            ? 'create-order__field-select--null'
                            : ''
                        }`}>
                    <option
                        value=''
                        className='create-order__field-select-option'
                        disabled={true}>
                        Select product type
                    </option>
                    {productTypes.map((productType) => {
                        return (
                            <option
                                key={productType.id}
                                className='create-order__field-select-option'
                                value={productType.id}>
                                {productType.name}
                            </option>
                        );
                    })}
                </select>

                <select
                    value={orderToCreate.product_id.value}
                    onChange={handleFieldChange('product_id')}
                    disabled={isProductLoading || !orderToCreate.product_type_id.value}
                    className={`create-order__field-select ${!orderToCreate.product_id.value
                            ? 'create-order__field-select--null'
                            : ''
                        }`}>
                    <option
                        value=''
                        className='create-order__field-select-option'
                        disabled={true}>
                        {orderToCreate.product_type_id.value
                            ? isProductLoading
                                ? 'Loading tenors'
                                : 'Select Tenor'
                            : 'Please select product type first'}
                    </option>
                    {products.map((product) => {
                        return (
                            <option
                                key={product.id}
                                className='create-order__field-select-option'
                                value={product.id}>
                                {product.tenor}
                            </option>
                        );
                    })}
                </select>

                {/* for block trade only */}
                {roleType === USER_ROLES.TRADER && isBlock ? (
                    <select
                        value={orderToCreate.counterparty_company_id.value}
                        onChange={handleFieldChange('counterparty_company_id')}
                        disabled={isProductLoading ||
                            !orderToCreate.product_type_id.value}
                        className={`create-order__field-select ${!orderToCreate.counterparty_company_id.value
                                ? 'create-order__field-select--null'
                                : ''
                            }`}>
                        <option
                            value=''
                            className='create-order__field-select-option'
                            disabled={true}>
                            {isProductLoading
                                ? 'Loading counterparties'
                                : 'Select a counterparty'}
                        </option>
                        {counterparties.map((company) => {
                            return (
                                <option
                                    key={company.id}
                                    className='create-order__field-select-option'
                                    value={company.id}>
                                    {company.name}
                                </option>
                            );
                        })}
                    </select>
                ) : null}

                {roleType === USER_ROLES.TRADER && isBlock ? (
                    <select
                        value={orderToCreate.counterparty_trader_id.value}
                        onChange={handleFieldChange('counterparty_trader_id')}
                        disabled={isProductLoading ||
                            !orderToCreate.product_type_id.value}
                        className={`create-order__field-select ${!orderToCreate.counterparty_trader_id.value
                                ? 'create-order__field-select--null'
                                : ''
                            }`}>
                        <option
                            value=''
                            className='create-order__field-select-option'
                            disabled={true}>
                            {isProductLoading
                                ? 'Loading traders'
                                : 'Select a trader'}
                        </option>
                        {selectedCounterparty
                            ? selectedCounterparty.traders.map((trader) => {
                                return (
                                    <option
                                        key={trader.id}
                                        className='create-order__field-select-option'
                                        value={trader.id}>
                                        {trader.name}
                                    </option>
                                );
                            })
                            : null}
                    </select>
                ) : null}
                {/* end for block trade only */}

                {/* {roleType === USER_ROLES.TRADER ? <div className='create-order__company-name'>
                    <div>
                        Company Name
                    </div>
                    <div>
                        <div className='create-order__name-hidden'>
                            Hidden
                        </div>
                        <div className='create-order__switch'>
                            <Switch value={orderToCreate.is_visible.value} onChange={handleFieldChange('is_visible')} />
                        </div>
                        <div className='create-order__name-visible'>
                            Visible
                        </div>
                    </div>

                </div> : null} */}

                <div className='create-order__field-group'>
                    <div className='create-order__field'>
                        <label className='create-order__field-label'>
                            Price
                            {orderToCreate.currency
                                ? `(${orderToCreate.currency})`
                                : null}
                        </label>
                        <NumberInput
                            type='number'
                            canBeNegative={true}
                            precision='2'
                            step={orderToCreate.price_increment}
                            value={orderToCreate.price.isDirty
                                ? orderToCreate.price.value
                                : orderToCreate.default_price}
                            isValid={orderToCreate.price.isValid}
                            onChange={handleFieldChange('price')}
                            className='create-order__field-input' />
                    </div>

                    <div className='create-order__field'>
                        <label className='create-order__field-label'>Qty</label>
                        <NumberInput
                            type='number'
                            min='0'
                            step={orderToCreate.volume_increment}
                            value={orderToCreate.volume.isDirty
                                ? orderToCreate.volume.value
                                : orderToCreate.default_volume}
                            isValid={orderToCreate.volume.isValid}
                            onChange={handleFieldChange('volume')}
                            className='create-order__field-input' />
                    </div>
                </div>
                {!isBlock ? (
                    <div className='create-order__toggles'>
                        <Tippy
                            allowHTML={true}
                            placement='top-end'
                            className='pt-tooltip'
                            content={<div>Select the order type</div>}>
                            <div className='create-order__type-row'>
                                <div>Firm</div>
                                <div className='create-order__switch'>
                                    <Switch
                                        value={!orderToCreate.is_firm.value}
                                        onChange={handleFieldChange('is_firm')} />
                                </div>
                                <div>Indicative</div>
                            </div>
                        </Tippy>
                        <Tippy
                            allowHTML={true}
                            placement='top-end'
                            className='pt-tooltip'
                            content={<div>Select the order name visibility</div>}>
                            <div className='create-order__type-row'>
                                <div>Hidden</div>
                                <div>
                                    <Switch
                                        value={orderToCreate.is_visible.value}
                                        onChange={handleFieldChange(
                                            'is_visible'
                                        )} />
                                </div>
                                <div>Visible</div>
                            </div>
                        </Tippy>
                    </div>
                ) : null}

                {roleType === USER_ROLES.TRADER ? (
                    <div className='create-order__brokerages-list'>
                        {isBlock ? (
                            <label className='create-order__field-label create-order__field-label--wide'>
                                Your Broker
                            </label>
                        ) : null}

                        <BrokerageSelect
                            isMainPlatform={isInMainPlatform()}
                            error={orderToCreate.brokerages.isValid
                                ? null
                                : 'Select a broker'}
                            brokeragesList={brokeragesList}
                            defaultSelectedBrokerages={orderToCreate.brokerages.value}
                            isBlock={isBlock}
                            readOnly={true}
                            onChange={handleBrokerageChange} />
                    </div>
                ) : null}

                {roleType === USER_ROLES.BROKERAGE ? (
                    <div className={classNames('create-order__field', { 'create-order__field--inline': isBlock })}>
                        <label className={classNames('create-order__field-label', { 'create-order__field-label--inline': isBlock })}>
                            Primary Client
                        </label>

                        <select
                            value={orderToCreate.company_id.value}
                            onChange={handleFieldChange('company_id')}
                            disabled={isProductLoading ||
                                !orderToCreate.product_type_id.value}
                            className={`create-order__field-select create-order__field-select--company ${isBlock ? 'create-order__field-select--inline' : null} ${!orderToCreate.company_id.value
                                    ? 'create-order__field-select--null'
                                    : ''
                                }`}>
                            <option
                                value=''
                                className='create-order__field-select-option'
                                disabled={true}>
                                {isProductLoading
                                    ? 'Loading companies'
                                    : 'Select a company'}
                            </option>
                            {companies.map((company) => {
                                return (
                                    <option
                                        key={company.id}
                                        className='create-order__field-select-option'
                                        value={company.id}>
                                        {company.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                ) : null}

                {roleType === USER_ROLES.BROKERAGE ? (
                    <div className={classNames('create-order__field', { 'create-order__field--inline': isBlock })}>
                        <label className={classNames('create-order__field-label', { 'create-order__field-label--inline': isBlock })}>
                            Primary Trader
                        </label>

                        <select
                            value={orderToCreate.trader_id.value}
                            onChange={handleFieldChange('trader_id')}
                            disabled={isProductLoading ||
                                !orderToCreate.product_type_id.value}
                            className={`create-order__field-select create-order__field-select--company ${isBlock ? 'create-order__field-select--inline' : null} ${!orderToCreate.trader_id.value
                                    ? 'create-order__field-select--null'
                                    : ''
                                }`}>
                            <option
                                value=''
                                className='create-order__field-select-option'
                                disabled={true}>
                                {isProductLoading
                                    ? 'Loading traders'
                                    : 'Select a trader'}
                            </option>
                            {selectedCompany
                                ? selectedCompany.traders.map((trader) => {
                                    return (
                                        <option
                                            key={trader.id}
                                            className='create-order__field-select-option'
                                            value={trader.id}>
                                            {trader.name}
                                        </option>
                                    );
                                })
                                : null}
                        </select>
                    </div>
                ) : null}

                {roleType === USER_ROLES.BROKERAGE && isBlock ? (
                    <div className={classNames('create-order__field', { 'create-order__field--inline': isBlock })}>
                        <label className={classNames('create-order__field-label', { 'create-order__field-label--inline': isBlock })}>
                            Secondary Client
                        </label>

                        <select
                            value={orderToCreate.secondary_company_id.value}
                            onChange={handleFieldChange('secondary_company_id')}
                            disabled={isProductLoading || isCounterpartyLoading || !orderToCreate.company_id.value ||
                                !orderToCreate.product_type_id.value}
                            className={`create-order__field-select create-order__field-select--company ${isBlock ? 'create-order__field-select--inline' : null} ${!orderToCreate.secondary_company_id.value
                                    ? 'create-order__field-select--null'
                                    : ''
                                }`}>
                            <option
                                value=''
                                className='create-order__field-select-option'
                                disabled={true}>
                                {isProductLoading
                                    ? 'Loading companies' : isCounterpartyLoading ? 'Loading counterparties'
                                        : 'Select a company'}
                            </option>
                            {secondaryCompanies.map((company) => {
                                return (
                                    <option
                                        key={company.id}
                                        className='create-order__field-select-option'
                                        value={company.id}>
                                        {company.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                ) : null}

                {roleType === USER_ROLES.BROKERAGE && isBlock ? (
                    <div className={classNames('create-order__field', { 'create-order__field--inline': isBlock })}>
                        <label className={classNames('create-order__field-label', { 'create-order__field-label--inline': isBlock })}>
                            Secondary Trader
                        </label>

                        <select
                            value={orderToCreate.secondary_trader_id.value}
                            onChange={handleFieldChange('secondary_trader_id')}
                            disabled={isProductLoading || isCounterpartyLoading || !orderToCreate.company_id.value ||
                                !orderToCreate.product_type_id.value}
                            className={`create-order__field-select create-order__field-select--company ${isBlock ? 'create-order__field-select--inline' : null} ${!orderToCreate.secondary_trader_id.value
                                    ? 'create-order__field-select--null'
                                    : ''
                                }`}>
                            <option
                                value=''
                                className='create-order__field-select-option'
                                disabled={true}>
                                {isProductLoading || isCounterpartyLoading
                                    ? 'Loading traders'
                                    : 'Select a trader'}
                            </option>
                            {selectedSecondaryCompany
                                ? selectedSecondaryCompany.traders.map((trader) => {
                                    return (
                                        <option
                                            key={trader.id}
                                            className='create-order__field-select-option'
                                            value={trader.id}>
                                            {trader.name}
                                        </option>
                                    );
                                })
                                : null}
                        </select>
                    </div>
                ) : null}

                {/* {roleType === USER_ROLES.BROKERAGE ? <div className='create-order__company-name'>
                    <div>
                        Client Name
                    </div>
                    <div>
                        <div className='create-order__name-hidden'>
                            Hidden
                        </div>
                        <div className='create-order__switch'>
                            <Switch value={orderToCreate.is_visible.value} onChange={handleFieldChange('is_visible')} />
                        </div>
                        <div className='create-order__name-visible'>
                            Visible
                        </div>
                    </div>

                </div> : null} */}
                {roleType === USER_ROLES.TRADER && isBlock ? (
                    <div className='create-order__commision-box'>
                        <img
                            className='create-order__info-icon'
                            src={infoGrey}
                            alt='error' />
                        {profile.trader.company.is_platform_user
                            ? `${platformTitle} will charge you your standard platform service fee for this transaction. Please note, no post-trade services will be provided by them.`
                            : `${platformTitle} Financial will charge you your standard brokerage fee, as agreed, for this block trade.`
                        }
                    </div>
                ) : null}

                {!isBlock &&
                <div className='create-order__gtc-container'>
                    <GTCCheckBox isGtc={isGtc} setIsGtc={setIsGtc} isNewOrder={true}/>
                </div>
                }

                <div className='create-order__condition-box'>
                    <div className='create-order__button-wrapper'>
                        <Button
                            isDisabled={!(typeof isFormValid === 'function'
                                ? isFormValid()
                                : isFormValid)}
                            onClick={handleBuyClick}
                            className='create-order__button create-order__button--buy'>
                            Buy
                        </Button>
                        <Button
                            isDisabled={!(typeof isFormValid === 'function'
                                ? isFormValid()
                                : isFormValid)}
                            onClick={handleSellClick}
                            className='create-order__button create-order__button--sell'>
                            Sell
                        </Button>
                    </div>
                    {conditionsActive ? (
                        <>
                            <div
                                onClick={onClickConditions}
                                className='create-order__add-conditions'>
                                Remove Special Conditions
                            </div>
                            <textarea
                                rows={2}
                                placeholder='FFA 4%'
                                onChange={handleFieldChange('note')}
                                value={orderToCreate.note.value ?? ''}
                                className='create-order__field-textarea' />
                        </>
                    ) : (
                        <div
                            onClick={onClickConditions}
                            className='create-order__add-conditions create-order__add-conditions--no-margin'>
                            Add Special Conditions
                        </div>
                    )}
                </div>
            </form>
        </Modal>
    );
}

export default ModalCreateOrderScreen;
