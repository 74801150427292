import * as Sentry from '@sentry/browser';
import { store } from 'redux/store';
import { getMessageByResponseError } from './apiIntercepor-utils/error';
import { getMessageByResponseSuccess } from './apiIntercepor-utils/success';
import { AXIOS, TRADING_AXIOS } from 'config/constants';
import { notifType, notify } from 'utils/notifSender';
import { handleLogout } from 'redux/reducers/auth';
import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
            cacheTime: 1000 * 60 * 60
        }
    }
});

export const queryClientKeys = {
    useAllClientsApi: 'useAllClientsApi',
    useCounterPartiesApi: 'useCounterPartiesApi',
    useSecondaryClientssApi: 'useSecondaryClientssApi',
    useGetTenderMyCountersApi: 'useGetTenderMyCountersApi',
    useGetTendersApi: 'useGetTendersApi',
    useGetMyTendersApi: 'useGetMyTendersApi',
    useGetMyTenderCountersApi: 'useGetMyTenderCountersApi',
    useAllCountriesApi: 'useAllCountriesApi',
    useGetAllBrokeragesApi: 'useGetAllBrokeragesApi',
    useGetBrokeragesApi: 'useGetBrokeragesApi',
    useGetProductTypeInfinite: 'useGetProductTypeInfinite',
    useGetTraderConterpartyLimitsApi: 'useGetTraderConterpartyLimitsApi',
    useGetBrokeragePublicApi: (subdomain) => `useGetBrokeragePublicApi${subdomain}`
};

const onError = (response, err) => {
    const { dispatch } = store;

    let message = '';

    if (response) {
        const { status } = response;

        if (status >= 500) {
            Sentry.captureException(response);
        }

        message = getMessageByResponseError({
            status: response.status,
            // endpoint: response.config.url.replace(API_URL, '')
            endpoint: response.config.url
        });

        if (!message && status === 401) {
            notify('Your session is expired, please login again!', notifType.DANGER);
            dispatch(handleLogout(false));
        }

        if (status === 401 && response.data?.message) {
            notify(response.data.message, notifType.DANGER);

            return;
        }

        if (status === 422) {
            if (response.data.errors) {
                const err = new Error(response.statusText);

                err.response = response;
                throw err;
            }
        }
    } else {
        Sentry.captureException(err);

        if (err.message === 'Network Error') {
            notify('Network Error! Cannot connect to the server.', notifType.DANGER);
        }
    }

    if (message) {
        notify(message, notifType.DANGER);
    }
};

if (AXIOS) {
    AXIOS.interceptors.response.use(
        (response) => {
            if (response.status >= 200 && response.status < 300) {
                const message = getMessageByResponseSuccess({
                    method: response.config.method,
                    // endpoint: response.config.url.replace(API_URL, '')
                    endpoint: response.config.url
                });

                if (message) {
                    notify(message, notifType.SUCCESS);
                }

                return response;
            }

            onError(response);

            const err = new Error(response.statusText);

            err.response = response;
            throw err;
        },
        (err) => {
            onError(err.response, err);

            return Promise.reject(err);
        }
    );
}

if (TRADING_AXIOS) {
    TRADING_AXIOS.interceptors.response.use(
        (response) => {
            if (response.status >= 200 && response.status < 300) {
                const message = getMessageByResponseSuccess({
                    method: response.config.method,
                    // endpoint: response.config.url.replace(API_URL, '')
                    endpoint: response.config.url
                });

                if (message) {
                    notify(message, notifType.SUCCESS);
                }

                return response;
            }

            onError(response);

            const err = new Error(response.statusText);

            err.response = response;
            throw err;
        },
        (err) => {
            onError(err.response, err);

            return Promise.reject(err);
        }
    );
}
